import { useTranslation } from "react-i18next";

const CustomButton = (props: any) => {
  const {
    disabled = false,
    onClick = () => {},
    label = "",
    type = "button",
    classNames = "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-[#9E9E9E]",
  } = props;
  const { t } = useTranslation();
  return (
    <button
      className={classNames}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {t(label)}
    </button>
  );
};

export default CustomButton;
