import { useTranslation } from "react-i18next";

const SelectableBoxListing = (props: any) => {
  const {
    list = [],
    onClick = () => {},
    containerClasses = "flex flex-col gap-3",
    listingClasses = "rounded-xl min-h-[56px] py-2 pr-3 pl-4 shadow-c",
    labelClasses = "ml-1 md:text-md max-sm:!text-sm",
  } = props;
  const { t } = useTranslation();
  return (
    <div className={containerClasses}>
      {list.map((listItem: any, index: any) => {
        return (
          <div
            key={index}
            className={`${listingClasses}${
              listItem.active || listItem.selected
                ? " border-1 border-[#037375]"
                : ""
            }`}
            onClick={() => {
              onClick(listItem.name || index);
            }}
          >
            <div className="flex w-full items-center">
              <img
                src={`./images/${listItem.image}.svg`}
                style={{ height: 39, width: 45 }}
              />
              <label className={labelClasses}>{t(listItem?.name)}</label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SelectableBoxListing;
