import { Form, Formik } from "formik";
import CountryMultiSelect from "../CountryMultiSelect";
import FormErrorText from "../FormErrorText";
import * as Sentry from "@sentry/browser";
import {
  addCompanyToState,
  editUser,
  setSignUpData,
} from "../../store/features/Auth/AuthSlice";
import {
  addCompany,
  addUser,
  editCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import { extractErrorMessage } from "../../helpers";
import { toast } from "material-react-toastify";
import { isEmpty } from "../../utilities/help";
import CustomButton from "../CustomButton";
import { KeyboardArrowDown } from "@material-ui/icons";

const CompanyEnvisionedCountries = (props: any) => {
  const {
    t,
    companySendingEnvisionedCountries,
    setcompanyReceivingEnvisionedCountries,
    setcompanySendingEnvisionedCountries,
    companyReceivingEnvisionedCountries,
    countryListing,
    dispatch,
    Yup,
    signUpData,
    setprogressBarData,
    setActiveStep,
    setErrorsState,
    country,
    user,
  } = props;

  return (
    <div className="flex flex-col justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("envisioned_countries")}
      </div>
      <div className="opacity-75 text-center mb-3">
        {t("company_envisioned_countries_subtitle")}
      </div>
      <Formik
        validateOnMount
        enableReinitialize
        key="companyenvisionedcountries"
        initialValues={{
          companySendingEnvisionedCountries:
            signUpData?.companySendingEnvisionedCountries ||
            user?.progression?.companySendingEnvisionedCountries ||
            companySendingEnvisionedCountries,
          companyReceivingEnvisionedCountries:
            signUpData?.companyReceivingEnvisionedCountries ||
            user?.progression?.companyReceivingEnvisionedCountries ||
            companyReceivingEnvisionedCountries,
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            const receivingIds = countryListing
              .filter((country: any) =>
                data.companyReceivingEnvisionedCountries.includes(country.name)
              )
              .map((country: any) => country.id);
            const sendingIds = countryListing
              .filter((country: any) =>
                data.companySendingEnvisionedCountries.includes(country.name)
              )
              .map((country: any) => country.id);

            dispatch(
              setSignUpData({
                ...signUpData,
                // receiving_countries_ids: [...receivingIds, ...sendingIds],
                registered_id: `${new Date().getTime()}`,
                receiving_countries_ids: receivingIds,
                sending_countries_ids: sendingIds,
                ...data,
              })
            );
            saveRegistrationProgress({
              ...signUpData,
              // receiving_countries_ids: [...receivingIds, ...sendingIds],
              receiving_countries_ids: receivingIds,
              sending_countries_ids: sendingIds,
              ...data,
              registered_id: `${new Date().getTime()}`,
              step: 3,
            });

            // xxxx

            setSubmitting(true);
            setErrorsState([]);
            try {
              if (
                user?.company !== null &&
                signUpData?.company !== undefined &&
                signUpData?.company !== null
              ) {
                let companyPayload: any = {
                  activity_id: signUpData.activity_id,
                  is_sole_proprietorship: signUpData.is_sole_proprietorship,
                  revenue_level_id: signUpData.revenue_level_id,
                  receiving_countries_ids: [...receivingIds, ...sendingIds],
                  registered_id: `${new Date().getTime()}`,
                  country_code:
                    parseInt(signUpData?.country_code) || parseInt(country),
                };

                // if job is not custom, add activity id
                if (!isEmpty(signUpData?.job)) {
                  delete companyPayload.activity_id;
                  companyPayload.job = signUpData.job;
                } else {
                  delete companyPayload.job;
                }

                //edit the company instead...
                editCompany(companyPayload, user?.company.id)
                  .then((res: any) => {
                    dispatch(addCompanyToState(res.company));
                    setprogressBarData({
                      progress: 100,
                      title: "company_verification",
                    });
                    setActiveStep(4);
                    dispatch(
                      setSignUpData({
                        ...signUpData,
                        company: res.company,
                      })
                    );
                  })
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                    const err = extractErrorMessage(error);
                    if (err) {
                      setErrorsState((prevState: any[]) => [
                        ...prevState,
                        ...err,
                      ]);
                    }
                  })
                  .finally(() => setSubmitting(false));
              } else {
                let payload = {
                  ...signUpData,
                  // receiving_countries_ids: receivingIds,
                  // sending_countries_ids: sendingIds,
                };
                delete payload.address_line;
                delete payload.city;
                delete payload.companyReceivingEnvisionedCountries;
                delete payload.companySendingEnvisionedCountries;
                delete payload.is_sole_proprietorship;
                delete payload.company;
                delete payload.residency;
                delete payload.step;
                delete payload.revenue_level_id;
                delete payload.countryName;

                addUser(payload, "business")
                  .then((res: any) => {
                    dispatch(editUser(res?.user));

                    let companyPayload: any = {
                      activity_id: signUpData.activity_id,
                      revenue_level_id: signUpData.revenue_level_id,
                      receiving_countries_ids: [...receivingIds, ...sendingIds],
                      registered_id: `${new Date().getTime()}`,
                      country_code:
                        parseInt(signUpData?.country_code) || parseInt(country),
                      is_sole_proprietorship: signUpData.is_sole_proprietorship,
                    };

                    if (
                      isEmpty(signUpData.activity_id) &&
                      !isEmpty(signUpData?.job)
                    ) {
                      delete companyPayload.activity_id;
                      companyPayload.job = signUpData?.job;
                    } else {
                      delete companyPayload.job;
                    }

                    addCompany(companyPayload)
                      .then((res: any) => {
                        dispatch(addCompanyToState(res.company));
                        dispatch(
                          setSignUpData({
                            ...signUpData,
                            company: res.company,
                          })
                        );

                        setprogressBarData({
                          progress: 100,
                          title: "company_verification",
                        });
                        setActiveStep(4);
                      })
                      .catch((error: any) => {
                        if (error?.data?.errors) {
                          try {
                            Object.keys(error?.data?.errors).forEach(
                              (error: any) => {
                                toast.error(`${t(error)}${t("is_invalid")}`);
                              }
                            );
                          } catch (error) {
                            Sentry.captureException(error);
                          }
                        }
                        toast.warning(t(error?.data?.message));
                        Sentry.captureException(error);
                        const err = extractErrorMessage(error);
                        if (err) {
                          setErrorsState((prevState: any[]) => [
                            ...prevState,
                            ...err,
                          ]);
                        }
                      })
                      .finally(() => setSubmitting(false));
                  })
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                    setSubmitting(false);
                    if (error?.data?.errors) {
                      try {
                        Object.keys(error?.data?.errors).forEach(
                          (error: any) => {
                            toast.error(`${t(error)}${t("is_invalid")}`);
                          }
                        );
                      } catch (error) {
                        Sentry.captureException(error);
                      }
                    }
                  });
              }
            } catch (error: any) {
              Sentry.captureException(error);
              setSubmitting(false);
            }
            // xxxxx
          } catch (error: any) {
            Sentry.captureException(error);
          }
        }}
        validationSchema={Yup.object().shape({
          companySendingEnvisionedCountries: Yup.array()
            .min(1, "transfer_countries_required")
            .required("required"),
          companyReceivingEnvisionedCountries: Yup.array()
            .min(1, "transfer_countries_required")
            .required("required"),
        })}
      >
        {({ errors, setFieldValue, isSubmitting, isValid, values }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <div className="mb-2 text-gray-700">
                {t("company_receiving_countries_1")}
                <b>{t("company_receiving_countries_2")}</b>
              </div>
              <CountryMultiSelect
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                selectedValues={
                  values.companyReceivingEnvisionedCountries ||
                  companyReceivingEnvisionedCountries
                }
                placeholder={t("company_receiving_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  setcompanyReceivingEnvisionedCountries(e);
                  setFieldValue("companyReceivingEnvisionedCountries", e);
                }}
                customClasses="!rounded-xl h-[50px] w-full border-1 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              />
              {errors?.companyReceivingEnvisionedCountries && (
                <FormErrorText
                  fontSize={12}
                  classNames="text-xs"
                  errorMessage={errors?.companyReceivingEnvisionedCountries}
                />
              )}
            </div>
            <div className="flex flex-col">
              <div className="mb-2 text-gray-700">
                {t("sending_countries_1")}
                <b>{t("sending_countries_2")}</b>
              </div>
              <CountryMultiSelect
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                selectedValues={
                  values.companySendingEnvisionedCountries ||
                  companySendingEnvisionedCountries
                }
                placeholder={t("company_receiving_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  setcompanySendingEnvisionedCountries(e);
                  setFieldValue("companySendingEnvisionedCountries", e);
                }}
                customClasses="!rounded-xl h-[50px] w-full border-1 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              />
              {errors?.companySendingEnvisionedCountries && (
                <FormErrorText
                  fontSize={12}
                  classNames="text-xs"
                  errorMessage={errors?.companySendingEnvisionedCountries}
                />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <CustomButton
                label="back"
                onClick={() => {
                  setprogressBarData({
                    progress: 50,
                    title: "Company_Information",
                  });
                  setActiveStep(2);
                }}
              />
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                disabled={isSubmitting || !isValid}
                type="submit"
                data-individual="individual"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyEnvisionedCountries;
