const CustomInputField = (props: any) => {
  const {
    mainContainerClasses = "w-full mb-1",
    label,
    labelClasses = "mb-1 text-gray-600 text-[12.6px]",
    type = "text",
    inputClasses = "!rounded-xl h-[50px] w-full border-1 pl-5 pr-5 border-[rgba(0,0,0,0.2)]",
    value,
    name,
    handleChange,
    disabled = false,
    placeholder,
    onBlur,
    error = "",
    touched = false,
    errorClasses = "mt-2 text-red-400 text-xs",
  } = props;
  const isError = error === "" || error === "undefined" ? false : true;

  return (
    <>
      <div className={mainContainerClasses}>
        {label && <label className={labelClasses}>{label}</label>}
        <input
          type={type}
          className={inputClasses}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={onBlur}
        />
        {isError && <small className={errorClasses}>{error}</small>}
      </div>
    </>
  );
};

export default CustomInputField;
