import { Form, Formik } from "formik";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import CustomDropdown from "../CustomDropdown";
import FormErrorText from "../FormErrorText";
import { Alert } from "@material-ui/lab";
import { saveRegistrationProgress } from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "../../utilities/help";
import { KeyboardArrowDown } from "@material-ui/icons";
import CustomInputField from "./CustomInputField";
import CustomButton from "../CustomButton";

const CompanyActivityRevenue = (props: any) => {
  const {
    t,
    signUpData,
    user,
    jobListing,
    dispatch,
    Yup,
    errorsState,
    setActiveStep,
    setprogressBarData,
    revenueOptions,
  } = props;

  const validationSchema = Yup.object().shape({
    job: Yup.string().when(["customJob", "activity_id"], {
      is: (customJob: any, activity_id: any) => {
        return (
          (customJob == "" && activity_id == "") ||
          (customJob == null && activity_id == null) ||
          (customJob == undefined && activity_id == undefined)
        );
      },
      then: Yup.string().required("activity_err"),
    }),
    customJob: Yup.string().test(
      "yourTestCondition",
      "activity_err",
      function (value: any, context: any) {
        return (
          (((context.parent.job !== "" && context.parent.job !== undefined) ||
            (context.parent.activity_id !== "" &&
              context.parent.activity_id !== undefined)) &&
            context.parent.job !== "Autres activités") ||
          (context.parent.job == "Autres activités" &&
            value !== "" &&
            value !== undefined)
        );
      }
    ),
    monthly_revenue: Yup.string().required("company_revenue_required"),
  });

  return (
    <div className="flex flex-col justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("company_activity_title")}
      </div>
      <div className="opacity-75 text-center mb-3">{t("coInfo")}</div>
      <Formik
        validateOnMount
        enableReinitialize
        validateOnBlur
        validateOnChange
        key="form5"
        initialValues={{
          activity_id: signUpData?.job
            ? ""
            : signUpData?.activity_id || user?.progression?.activity_id || "",
          job: signUpData?.job || user?.progression?.job || "",
          monthly_revenue:
            signUpData?.monthly_revenue ||
            user?.monthly_revenue ||
            user?.progression?.monthly_revenue ||
            "",
          customJob: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            // activity id in case on non custom job
            const activityId: any = jobListing.find(
              (job: any) => job.name == data.job
            );

            const revenueObject = revenueOptions.find(
              (revenue: any) => revenue.name == data.monthly_revenue
            );

            let payload = {
              ...user?.progression,
              ...signUpData,
              ...data,
              job: data.job == "Autres activités" ? data.customJob : data.job,
              monthly_revenue: data.monthly_revenue,
              revenue_level_id: revenueObject.id,
              reason_for_modification: "Adding revenue details",
            };
            delete payload.customJob;

            if (!isEmpty(payload?.job)) {
              payload.job =
                data.job == "Autres activités" ? data.customJob : data.job;
              delete payload.activity_id;
            } else if (!isEmpty(payload?.activity_id)) {
              delete payload.job;
            }

            dispatch(setSignUpData(payload));
            saveRegistrationProgress({ ...payload, step: 2 }).finally(() => {
              window.heap.track("Company Revenue information set", {
                ...data,
                email: user?.email,
              });

              setActiveStep("companyEnvisionedCountries");
              setprogressBarData({
                progress: 75,
                title: "envisioned_countries",
              });
              setSubmitting(false);
            });
          } catch (error: any) {
            Sentry.captureException(error);
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <div className="mb-2">{t("activity")}</div>
              <CustomDropdown
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                placeholder={t("job_select")}
                otherValue={"Autres activités"}
                defaultValue={
                  jobListing.find((job: any) => job.id == values?.activity_id)
                    ?.name
                    ? jobListing.find(
                        (job: any) => job.id == values?.activity_id
                      )?.name
                    : values.job
                }
                list={jobListing}
                setCustomProvider={(val: any) => {
                  if (val !== "") handleChange("customJob")(signUpData?.job);
                }}
                onchange={(val: any) => {
                  try {
                    if (val == "Autres activités") {
                      setFieldValue("activity_id", "");
                      setFieldValue("job", val);
                    } else {
                      const activityId: any = jobListing.find(
                        (job: any) => job.name == val
                      );
                      setFieldValue("activity_id", activityId?.id);
                      setFieldValue("job", "");
                      setFieldValue("customJob", "");
                    }
                  } catch (error) {
                    Sentry.captureException(error);
                  }
                }}
                customClasses="!rounded-xl h-[50px] w-full border-1 mb-2 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              />

              {values.job == "Autres activités" && (
                <CustomInputField
                  mainContainerClasses="w-full mb-2"
                  type="text"
                  name="customJob"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("job")}
                  value={values.customJob}
                  error={isSubmitting ? "" : t(`${errors.customJob}`)}
                  touched={touched.customJob}
                />
              )}
            </div>

            <div className="flex flex-col">
              <div className="mb-2">{t("revenue_level")}</div>
              <CustomDropdown
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                placeholder={t("revenue_select")}
                otherValue={
                  revenueOptions.length > 0 && revenueOptions[0]?.name
                }
                defaultValue={values?.monthly_revenue}
                customClasses="!rounded-xl h-[50px] w-full border-1 mb-2 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
                list={revenueOptions}
                onchange={(val: any) => {
                  handleChange("monthly_revenue")(val);
                }}
              />

              {errors?.monthly_revenue && (
                <FormErrorText
                  fontSize={12}
                  classNames="text-xs"
                  errorMessage={isSubmitting ? "" : errors?.monthly_revenue}
                />
              )}
            </div>

            <div>
              {errorsState.length > 0 && (
                <div className="mb-4">
                  <Alert severity="error">{errorsState.join(" ,")}</Alert>
                </div>
              )}
            </div>
            <div className="flex justify-between gap-3">
              <CustomButton
                label="back"
                onClick={() => {
                  setActiveStep(1);
                  setprogressBarData({
                    progress: 25,
                    title: "personal_information",
                  });
                }}
              />
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                disabled={isSubmitting || !isValid}
                type="submit"
                data-individual="individual"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyActivityRevenue;
