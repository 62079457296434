import {
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import * as React from "react";
import { FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@material-ui/icons";

const MenuProps = {
  getContentAnchorEl: null,
};

export default function CountryMultiSelect(props: any) {
  const {
    customClasses,
    list,
    placeholder,
    onChange,
    selectedValues,
    isLoading = false,
    containerClasses = "w-full",
    CustomIcon = null,
  } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (selectedValues !== undefined) {
      setSelected(selectedValues);
    }
  }, [selectedValues]);

  const handlecheckboxChange = (name: any) => {
    if (selected.includes(name)) {
      const newSelectedValues = selected.filter(
        (selectedname: any) => selectedname !== name
      );
      setSelected(newSelectedValues);
      onChange && onChange(newSelectedValues);
    } else {
      const newSelectedValues = selected.concat([name]);
      setSelected(newSelectedValues);
      onChange && onChange(newSelectedValues);
    }
  };

  const [open, setopen] = React.useState(false);

  return (
    <FormControl className={containerClasses}>
      {isLoading && (
        <div className={customClasses + " flex justify-center"}>
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <Select
          open={open}
          onOpen={() => setopen(true)}
          IconComponent={() => (CustomIcon ? CustomIcon : <ArrowDropDown />)}
          // onClick={() => setopen(true)}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          disableUnderline={true}
          value={selected}
          className={customClasses}
          // onChange={handleChange}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return <div style={{ fontSize: "14px" }}>{placeholder}</div>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{
            className: "!text-wrap !pr-0 !text-base max-sm:!text-sm",
          }}
        >
          <div className="h-[280px] overflow-scroll">
            {list.map((listitem: any) => (
              <MenuItem
                key={listitem.name}
                value={listitem.name}
                onClick={() => handlecheckboxChange(listitem.name)}
              >
                <Checkbox checked={selected.indexOf(listitem.name) > -1} />
                <div className="text-base max-sm:text-sm">{listitem.name}</div>
                <img
                  src={`../flags/${listitem.flag}`}
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 15,
                  }}
                  className="mr-2 ml-auto"
                  alt=""
                />
              </MenuItem>
            ))}
          </div>
          <hr />
          <div className="p-2 pt-2 pb-0 flex gap-2">
            <button
              className="flex-1 w-full rounded-lg btn btn-block text-white capitalize md:text-md max-sm:!text-sm text-[12.6px]"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              data-individual="individual"
              onClick={() => {
                setopen(false);
              }}
            >
              {t("cancel")}
            </button>
            <button
              className="flex-1 w-full rounded-lg btn btn-block text-white capitalize md:text-md max-sm:!text-sm text-[12.6px]"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              data-individual="individual"
              disabled={selected.length == 0}
              onClick={() => setopen(false)}
            >
              {t("next")}
            </button>
          </div>
        </Select>
      )}
    </FormControl>
  );
}
