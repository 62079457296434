import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import { Formik } from "formik";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";
import CustomCheckBoxListingComponent from "../CustomCheckBoxListingComponent";
import CustomInputField from "../SignUp/CustomInputField";
import CustomButton from "../CustomButton";

const SignUpDiscoveryModal = ({
  onNext,
  extraclass,
  selectedValue,
  isDashboardModal,
}: any) => {
  const { t } = useTranslation();
  const selectAccount = [
    {
      name: "LinkedIn",
      checked: false,
      custom: false,
    },
    {
      name: "Google",
      checked: false,
      custom: false,
    },
    {
      name: "Facebook",
      checked: false,
      custom: false,
    },
    {
      name: "Instagram",
      checked: false,
      custom: false,
    },
    {
      name: "Tiktok",
      checked: false,
      custom: false,
    },
    {
      name: "Event",
      checked: false,
      custom: false,
    },
    {
      name: "Flyers",
      checked: false,
      custom: false,
    },
    {
      name: "Referral",
      checked: false,
      custom: true,
    },
    {
      name: "Other",
      checked: false,
      custom: true,
    },
  ];
  const [discoveryMethods, setdiscoveryMethods] = useState(selectAccount);
  const [otherValue, setotherValue] = useState("");

  // set selected value if coming back
  useEffect(() => {
    try {
      if (
        selectedValue &&
        selectedValue !== undefined &&
        selectedValue !== null
      ) {
        setdiscoveryMethods((prev: any) => {
          const isOther = prev.every(
            (itm: any) => itm.name !== selectedValue?.user_origin
          );

          return prev.map((itm: any) => {
            if (isOther && itm.name == "Other") {
              setotherValue(selectedValue?.user_origin);
              return { ...itm, checked: true };
            } else if (
              selectedValue?.user_origin == "Referral" &&
              itm.name == "Referral"
            ) {
              return { ...itm, checked: true };
            } else if (selectedValue?.user_origin == itm.name) {
              return { ...itm, checked: true };
            } else {
              return { ...itm, checked: false };
            }
          });
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [selectedValue]);

  const discoverySchema = Yup.object().shape(
    {
      discoveryOption: Yup.string().test(
        "yourTestCondition",
        "Please select any one of options",
        function (value, io) {
          return (
            (value !== undefined && value !== "") ||
            (io.parent.otherOption !== undefined &&
              io.parent.otherOption !== "")
          );
        }
      ),
      otherOption: Yup.string().test(
        "yourTestCondition",
        "other_field_error",
        function (value, io) {
          return (
            (value !== undefined && value !== "") ||
            (io.parent.discoveryOption !== undefined &&
              io.parent.discoveryOption !== "")
          );
        }
      ),
      referralCode: Yup.string(),
      event: Yup.string().test(
        "yourTestCondition",
        "event_error",
        function (value, io) {
          return (
            (value !== undefined &&
              value !== "" &&
              io.parent.discoveryOption == "Event") ||
            (io.parent.otherOption !== undefined &&
              io.parent.otherOption !== "") ||
            (io.parent.discoveryOption !== undefined &&
              io.parent.discoveryOption !== "" &&
              io.parent.discoveryOption !== "Event")
          );
        }
      ),
    },
    [["discoveryOption", "otherOption"]]
  );

  const handleLogout = () => {
    confirmAlert({
      title: t("loggingOut"),
      message: t("loggingOutSure"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {},
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="flex-center h-screen">
      <div
        className={`max-w-[600px] flex flex-col w-full min-h-[641px] py-7 px-6 max-sm:!px-4 bg-white rounded-lg shadow-c SignUpDiscoveryModal${
          extraclass ? extraclass : ""
        }`}
      >
        <h2 className="font-bold mx-10 text-center text-2xl">
          {t("discovery")}
        </h2>
        <div className="text-center opacity-75 mt-1 mb-7">
          {t("discoverysub")}
        </div>
        <Formik
          enableReinitialize
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            discoveryOption:
              (selectedValue?.user_origin && selectedValue?.user_origin) || "",
            otherOption: (otherValue !== "" && otherValue) || "",
            referralCode:
              (selectedValue?.user_origin == "Referral" &&
                selectedValue?.referral_code) ||
              "",
            event: (selectedValue?.event && selectedValue?.event) || "",
          }}
          validationSchema={discoverySchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (
              values.referralCode !== "" ||
              values.discoveryOption == "Referral"
            ) {
              if (values.referralCode == "") {
                onNext &&
                  onNext(
                    {
                      user_origin: "Referral",
                    },
                    setSubmitting
                  );
              } else {
                onNext &&
                  onNext(
                    {
                      user_origin: "Referral",
                      referral_code: values.referralCode,
                    },
                    setSubmitting
                  );
              }
            } else if (
              values.discoveryOption !== "" &&
              values.discoveryOption !== "Event"
            ) {
              onNext &&
                onNext({ user_origin: values.discoveryOption }, setSubmitting);
            } else if (
              values.discoveryOption == "Event" &&
              values.event !== ""
            ) {
              onNext &&
                onNext(
                  { user_origin: "Event", event: values.event },
                  setSubmitting
                );
            } else {
              onNext &&
                onNext({ user_origin: values.otherOption }, setSubmitting);
            }
          }}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            isValid,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <form
                className="individual-registration max-w-[328px] w-full mx-auto"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="mb-6">
                  <div className="flex flex-row customeDesignwithdraw customeCacheDesign">
                    <CustomCheckBoxListingComponent
                      extraListClasses="h-[39px]"
                      list={discoveryMethods?.filter((itm: any) =>
                        isDashboardModal == "true"
                          ? itm.name !== "Referral"
                          : true
                      )}
                      customCheckBoxClass="my-auto withdrawcheckbox"
                      customMainClasses="pb-3 pl-4 flex flex-col relative"
                      customListItemClasses={"font-bold"}
                      onChange={(value: any, id: any) => {
                        if (id == "Referral") {
                          handleChange("discoveryOption")("Referral");
                          handleChange("otherOption")("");
                          handleChange("referralCode")("");
                        }
                        if (id == "Other") {
                          handleChange("discoveryOption")("");
                          handleChange("otherOption")("");
                          handleChange("referralCode")("");
                        }
                        if (id !== "Other" && id !== "Referral") {
                          handleChange("discoveryOption")(id);
                          handleChange("otherOption")("");
                          handleChange("referralCode")("");
                        }
                        setdiscoveryMethods((p) =>
                          p.map((itm, itmind) =>
                            id == itm.name
                              ? { ...itm, checked: true }
                              : { ...itm, checked: false }
                          )
                        );
                      }}
                    />
                  </div>
                  {discoveryMethods.find(
                    (itm) => itm.name == "Other" && itm.checked
                  ) && (
                    <CustomInputField
                      name="otherOption"
                      type="text"
                      placeholder={t("discovery_other_heard")}
                      handleChange={(e: any) => {
                        handleChange("otherOption")(e.target.value);
                      }}
                      value={values.otherOption}
                      error={t(`${errors.otherOption}`)}
                      onBlur={() => {
                        handleBlur("otherOption");
                      }}
                      touched={touched.otherOption}
                    />
                  )}
                  {discoveryMethods.find(
                    (itm) => itm.name == "Referral" && itm.checked
                  ) && (
                    <CustomInputField
                      name="referralCode"
                      type="text"
                      placeholder={t("referral_code")}
                      handleChange={(e: any) => {
                        handleChange("referralCode")(e.target.value);
                      }}
                      value={values.referralCode}
                      error={t(`${errors.referralCode}`)}
                      onBlur={() => {
                        handleBlur("referralCode");
                      }}
                      touched={touched.referralCode}
                    />
                  )}
                  {discoveryMethods.find(
                    (itm) => itm.name == "Event" && itm.checked
                  ) && (
                    <CustomInputField
                      name="event"
                      type="text"
                      placeholder={t("event_placeholder")}
                      handleChange={(e: any) => {
                        handleChange("event")(e.target.value);
                      }}
                      value={values.event}
                      error={t(`${errors.event}`)}
                      onBlur={() => {
                        handleBlur("event");
                      }}
                      touched={touched.event}
                    />
                  )}
                </div>
              </form>

              <CustomButton
                classNames="min-h-[48px] w-full mt-auto rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                onClick={() => handleSubmit()}
                disabled={
                  isSubmitting ||
                  (values.discoveryOption == "Event" && values.event == "") ||
                  !(
                    values.referralCode !== "" ||
                    values.otherOption !== "" ||
                    values.discoveryOption !== ""
                  )
                }
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignUpDiscoveryModal;
