import { LinearProgress } from "@material-ui/core";

const SignUpProgressBar = (props: any) => {
  const {
    progress,
    label,
    t,
    customMainContainerClasses = "mb-5 flex flex-col mx-auto",
    customLabelClasses = "md:text-md max-sm:!text-sm",
  } = props;
  return (
    <div className={customMainContainerClasses}>
      <div className={customLabelClasses}>{t(label)}</div>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default SignUpProgressBar;
