import { Dialog } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";

const BulkPaymenetCloseDialogue = (props: any) => {
  const {
    openFailDialogLoss,
    handleClose,
    setopenFailDialogLoss,
    user,
    setBulkPaymentModal,
  } = props;
  const { t } = useTranslation();
  return (
    <Dialog open={openFailDialogLoss} onClose={handleClose}>
      {openFailDialogLoss === true &&
        confirmAlert({
          title: t("Lose_Csv_Toast"),
          overlayClassName: "zIndexClass",
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t("ok"),
              onClick: () => {
                setopenFailDialogLoss(false);
                setBulkPaymentModal();
                window.heap.track("Bulk transfer canceled", {
                  email: user.email,
                  country: user.country,
                  phone: user.full_phone_number,
                  name: user.full_name,
                });
              },
            },
            {
              label: t("cancel"),
              onClick: () => {
                setopenFailDialogLoss(false);
              },
            },
          ],
        })}
    </Dialog>
  );
};

export default BulkPaymenetCloseDialogue;
