import { Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const CustomModal = (props: any) => {
  const {
    containerClasses,
    titleClasses,
    title,
    contentClasses,
    content,
    modalClasses,
    backBtnClasses = "w-full rounded-lg btn btn-block rounded-lg-primary text-white md:text-md max-sm:!text-sm bg-[rgb(3, 115, 117)]",
    nextBtnClasses = "w-full rounded-lg btn btn-block rounded-lg-primary text-white md:text-md max-sm:!text-sm bg-[rgb(3, 115, 117)]",
    onNext,
    onBack,
    onBackTitle = "back",
    onNextTitle = "next",
    buttonContainerClasses,
    contentComponent,
    open,
    onClose,
    showCloseButton = false,
    showHorizontalRuler = false,
    headerClasses = "",
    disabled,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose && onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={containerClasses}>
        <div className={modalClasses}>
          <div className={headerClasses}>
            <div className={titleClasses}>{t(title)}</div>
            {showCloseButton && (
              <button
                onClick={onClose}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-md"
              >
                <Close />
              </button>
            )}
          </div>
          {showHorizontalRuler && <hr className="mb-4" />}
          <div className={contentClasses}>
            {contentComponent ? contentComponent : t(content)}
          </div>

          <div className={buttonContainerClasses}>
            {onBack && (
              <button className={backBtnClasses} onClick={onBack}>
                {t(onBackTitle)}
              </button>
            )}
            {onNext && (
              <button
                disabled={disabled}
                className={nextBtnClasses}
                onClick={onNext}
              >
                {t(onNextTitle)}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
