import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BulkCsvUploadFailModal = (props: any) => {
  const { openFailDialog, handleClose, setopenFailDialog, setopenDialog } =
    props;
  const { t } = useTranslation();
  return (
    <Dialog open={openFailDialog} onClose={() => handleClose}>
      <div className="p-10 col-md-12">
        <div className="flex items-center justify-center mb-6">
          <p className="font-bold text-center text-2xl">
            {t("upload_beneficiaries_details")}
          </p>
        </div>
        <div className="justify-center align-center flex circle-icon">
          <img
            src="/images/cancle-close.png"
            alt="cancle-check-circle.png"
            width={90}
          />
        </div>
        <div className="flex items-center justify-center mt-6 mb-6">
          <p className="font-regular text-center text-1xl text-gray-400">
            {t("Csv_Format_Error")}
          </p>
        </div>
        <div className="flex items-center justify-center mt-6 mb-6">
          <p className="font-regular text-center text-1xl text-gray-400">
            {t("download_template")}
          </p>
          <Link to="/sample.csv" target="_blank" download>
            <i
              className="fa fa-download text-7xl"
              aria-hidden="true"
              style={{
                color: "rgb(3, 115, 117)",
                fontSize: "26px",
              }}
            ></i>
          </Link>
        </div>
        <div className="flex flex-row justify-between my-3">
          <button
            onClick={() => {
              setopenDialog(true);
              setopenFailDialog(false);
            }}
            className="mr-2 w-2/4 px-10 rounded-md btn py-2.5 mx-2 font-bold text-white text-xs"
            style={{ backgroundColor: "#000000" }}
          >
            {t("retry")}
          </button>
          <button
            onClick={() => {
              setopenFailDialog(false);
            }}
            className="ml-2 w-2/4 px-10 rounded-md btn py-2.5 mx-2 font-bold text-white text-xs"
            style={{ backgroundColor: "#037375" }}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default BulkCsvUploadFailModal;
