import { Form, Formik } from "formik";
import {
  addUser,
  detachCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import FormErrorText from "../FormErrorText";
import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../utilities/help";
import { editUser } from "../../store/features/Auth/AuthSlice";
import { toast } from "material-react-toastify";
import CustomButton from "../CustomButton";

const ResidencySelection = (props: any) => {
  const {
    t,
    residencySelection,
    typeOfUser,
    user,
    signUpData,
    setActiveStep,
    setprogressBarData,
    Yup,
    activeStep,
    setresidencySelection,
    setresidencyLevelName,
    dispatch,
  } = props;

  const userFromAfrica = useMemo(() => {
    try {
      return [2, "2"].includes(signUpData?.country_code.toString().charAt(0));
    } catch (error) {
      return false;
    }
  }, [signUpData]);

  const residencyListData = [
    {
      id: 1,
      name: "residency_option_1",
      val: "Non EU Person",
      description: "non_eu_description",
      image: "suitcaserolling",
      selected: false,
    },
    {
      id: 2,
      name: "residency_option_2",
      val: "EU Person",
      description: "eu_description",
      image: "houseline",
      selected: false,
    },
  ];
  const [residencyList, setresidencyList] = useState(residencyListData);
  useEffect(() => {
    if (!isEmpty(residencySelection)) {
      if (
        (userFromAfrica && residencySelection !== "WAEMU/CEMAC Person") ||
        (!userFromAfrica && residencySelection == "WAEMU/CEMAC Person")
      ) {
        setisDisabled(true);
      } else {
        setisDisabled(false);
      }

      setresidencyList((prev: any) =>
        prev.map((residency: any) =>
          residency.val == residencySelection
            ? { ...residency, selected: true }
            : { ...residency, selected: false }
        )
      );
    }
  }, [residencySelection, userFromAfrica]);

  const handleResidencyChange = (index: any, checked: any) => {
    if (checked) {
      setresidencyList((prev: any) =>
        prev.map((residency: any, residencyIndex: any) =>
          residencyIndex == index
            ? { ...residency, selected: true }
            : { ...residency, selected: false }
        )
      );
    }
  };

  const [isDisabled, setisDisabled] = useState(false);

  const handleSubmit = (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      let payload = {
        ...user?.progression,
        ...signUpData,
        residency: data.residency,
      };
      delete payload.customJob;
      delete payload.company;
      delete payload.is_sole_proprietorship;
      delete payload.countryName;

      if (!isEmpty(signUpData?.job)) {
        payload.job =
          signUpData.job == "Autres activités"
            ? signUpData.customJob
            : signUpData.job;
        delete payload.activity_id;
      } else if (!isEmpty(signUpData?.activity_id)) {
        delete payload.job;
      }

      saveRegistrationProgress(payload).then(() => {
        // if company is attached, remove it
        if (
          (user?.company !== null && user?.company !== undefined) ||
          (signUpData?.company !== null && signUpData?.company !== undefined)
        ) {
          detachCompany()
            .then(() => {
              addUser(payload, usertype.value)
                .then((res: any) => {
                  if (
                    res?.missing_fields?.length < 1 &&
                    res?.completed == true
                  ) {
                    dispatch(editUser(res?.user));
                    setActiveStep(4);
                    setprogressBarData({
                      progress: 100,
                      title: "documents_and_selfie",
                    });
                    setresidencySelection(data.residency);
                  } else {
                    if (res?.missing_fields?.length > 0) {
                      res?.missing_fields.forEach((missingField: any) => {
                        toast.error(`${missingField} ${t("is_required")}`);
                      });
                    }
                  }
                })
                .catch((error: any) => {
                  toast.warning(t(error?.data?.message));
                  Sentry.captureException(error);
                  if (error?.data?.errors) {
                    try {
                      Object.keys(error?.data?.errors).forEach((error: any) => {
                        toast.error(`${t(error)}${t("is_invalid")}`);
                      });
                    } catch (error) {
                      Sentry.captureException(error);
                    }
                  }
                })
                .finally(() => setSubmitting(false));
            })
            .catch((error: any) => {
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
            });
        } else {
          addUser(payload, usertype.value)
            .then((res: any) => {
              if (res?.missing_fields?.length < 1 && res?.completed == true) {
                dispatch(editUser(res?.user));
                setActiveStep(4);
                setprogressBarData({
                  progress: 100,
                  title: "documents_and_selfie",
                });
                setresidencySelection(data.residency);
              } else {
                if (res?.missing_fields?.length > 0) {
                  res?.missing_fields.forEach((missingField: any) => {
                    toast.error(`${missingField} ${t("is_required")}`);
                  });
                }
              }
            })
            .catch((error: any) => {
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
              if (error?.data?.errors) {
                try {
                  Object.keys(error?.data?.errors).forEach((error: any) => {
                    toast.error(`${t(error)}${t("is_invalid")}`);
                  });
                } catch (error) {
                  Sentry.captureException(error);
                }
              }
            })
            .finally(() => setSubmitting(false));
        }
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="flex flex-col justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("current_residence")}
      </div>
      <div className="opacity-75 text-center mb-7">
        {t("specify_residency")}
      </div>
      <Formik
        validateOnMount
        enableReinitialize
        key="residenceSelection"
        initialValues={{
          residency: residencySelection || "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          handleSubmit(data, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          residency: Yup.string().required("residency_required"),
        })}
      >
        {({ errors, isSubmitting, handleChange, isValid, values }) => (
          <Form className="residency selection">
            {/* newui */}
            {/* <div className="flex flex-col gap-2 p-2 bacnkCheckBoxWrapper">
              {residencyList.map((residency: any, index: any) => {
                return (
                  <>
                    <div
                      key={index}
                      className="m-0 wrapperBankCheckBox innerBankBox"
                    >
                      <div className="wrapperBankCheckBoxwrapper min-w-fit">
                        <input
                          type="checkbox"
                          className="default:ring-2"
                          name={t(residency.name)}
                          onChange={(e) => {
                            if (
                              (userFromAfrica && index == 2) ||
                              (!userFromAfrica && index !== 2)
                            ) {
                              setisDisabled(false);
                            } else {
                              setisDisabled(true);
                            }

                            handleChange("residency")(residency.val);
                            handleResidencyChange(index, e.target.checked);
                            setresidencyLevelName(residency.val);
                            // }
                          }}
                          checked={residency.selected}
                        />
                        <span className="checkmark"></span>
                      </div>
                      <label
                        className={`md:text-md max-sm:!text-sm !text-[${
                          userFromAfrica && index == 2
                            ? "#4c4c4c"
                            : !userFromAfrica && index != 2
                            ? "#4c4c4c"
                            : "#8b8b8b"
                        }]`}
                      >
                        {t(residency.name)}
                      </label>
                    </div>

                    {residency.selected && (
                      <small className="innerBoxDesc mb-2 innerBankBox">
                        {t(residency.description)}
                      </small>
                    )}
                    {residency.selected && !userFromAfrica && index == 2 && (
                      <FormErrorText
                        classNames="innerBoxDesc mb-2 innerBankBox"
                        errorMessage={"umo_description_error"}
                      />
                    )}
                  </>
                );
              })}
            </div> */}

            <div className="mb-6">
              <div className="flex flex-col gap-4 p-2">
                {residencyList.map((residency: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`flex flex-col items-center justify-center gap-1 rounded-lg min-h-[56px] py-2 pr-3 pl-4 shadow-c${
                        residency.selected ? " border-1 border-[#037375]" : ""
                      }`}
                      onClick={() => {
                        if (
                          (userFromAfrica && index == 2) ||
                          (!userFromAfrica && index !== 2)
                        ) {
                          setisDisabled(false);
                        } else {
                          setisDisabled(true);
                        }

                        handleChange("residency")(residency.val);
                        handleResidencyChange(index, true);
                        setresidencyLevelName(residency.val);
                      }}
                    >
                      <div className="flex w-full items-center">
                        <img
                          src={`./images/${residency.image}.svg`}
                          style={{ height: 39, width: 45 }}
                        />
                        <label className="ml-2 font-bold opacity-75 md:text-md max-sm:!text-sm">
                          {t(residency?.name)}
                        </label>
                      </div>
                      {residency.selected && (
                        <div>{t(residency?.description)}</div>
                      )}
                    </div>
                  );
                })}
              </div>
              {errors?.residency && (
                <FormErrorText
                  fontSize={12}
                  classNames="text-xs px-2"
                  errorMessage={errors?.residency}
                />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <CustomButton
                label="back"
                onClick={() => {
                  setActiveStep("individualTransferInfo");
                  setprogressBarData({
                    progress: 60,
                    title: "receiving_countries",
                  });
                }}
                disabled={activeStep === 0}
              />
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                type="submit"
                data-individual="individual"
                disabled={isSubmitting || !isValid || isDisabled}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResidencySelection;
