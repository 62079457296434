import LangSwitch from "../LangSwitch";

const SignUpHeader = (props: any) => {
  const { handleLogout } = props;
  return (
    <div className="w-full z-10 fixed flex flex-row items-center justify-between px-4 pt-8 pb-10">
      <LangSwitch customMainContainerClasses="bg-[#FAFAFA] rounded-xl w-[120px] h-[52px] !text-sm font-semibold pl-3" />
      <button
        className="flex-center w-[48px] h-[48px] bg-[#696969] rounded-xl"
        onClick={handleLogout}
      >
        <i
          className="m-0 pl-1 w-min fa fa-sign-out"
          style={{ fontSize: 23, color: "white" }}
        />
      </button>
    </div>
  );
};

export default SignUpHeader;
