import React from "react";
import { useTranslation } from "react-i18next";

const FormErrorText = (props: any) => {
  const { t } = useTranslation();
  const { errorMessage, classNames = "", fontSize = 11 } = props;
  return (
    <p
      className={`mt-2 !text-red-400 ${classNames}`}
      style={{ fontSize: fontSize }}
    >
      {t(errorMessage)}
    </p>
  );
};

export default FormErrorText;
