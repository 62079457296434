import { useTranslation } from "react-i18next";

const CustomCheckBoxListingComponent = (props: any) => {
  const {
    list = [],
    onChange = () => {},
    extraListClasses = "",
    customMainClasses = null,
    nameKey = null,
    customCheckBoxClass = null,
    customListItemClasses = "",
    isDisabled = false,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <div
        className={
          customMainClasses
            ? customMainClasses
            : `p-2 flex flex-col gap-2 relative`
        }
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        {list &&
          list.length > 0 &&
          list.map((listItem: any, listItemIndex: any) => {
            return (
              <div
                key={listItem.name + listItemIndex}
                className={`m-0 flex ${extraListClasses}`}
              >
                <div className="flex-center mr-4">
                  <input
                    type="checkbox"
                    className={
                      customCheckBoxClass
                        ? customCheckBoxClass
                        : "customcheckbox my-auto"
                    }
                    onChange={(e) => {
                      onChange(e, listItem.id || listItem.name);
                    }}
                    name="Mobile Money"
                    checked={listItem.active || listItem.checked}
                  />
                </div>
                <div className={`flex-center ${customListItemClasses}`}>
                  {nameKey ? t(listItem?.[nameKey]) : t(listItem?.name)}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CustomCheckBoxListingComponent;
