import React from "react";
import MiniProfile from "../components/MiniProfile/MiniProfile";
import TransactionsCard from "../components/TransactionsCard/TransactionsCard";
import { useAppSelector } from "../store/hooks";
import Contacts from "../components/Contacts";
import { useTranslation } from "react-i18next";
import "../helpers/i18n";

interface IHeap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

var heap: IHeap;

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { transfer } = useAppSelector((state) => state.persistedReducer);

  return (
    <div>
      <div className="px-2 max-sm:mt-4">
        <MiniProfile />
        <div className="mt-4 text-gray-600 text-xs">
          <b>{t("Contacts")}</b>
          <Contacts reloadContacts={() => null} />
        </div>
      </div>

      <div className="mx-2 mt-2 mb-3 font-bold text-gray-600 text-xs">
        {t("Latest_Transactions")}
      </div>

      <TransactionsCard transactions={transfer?.transfers?.slice(0, 3)} />
    </div>
  );
};

export default Home;
