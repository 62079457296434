import { GoogleSpreadsheet } from "google-spreadsheet";
import { JWT } from "google-auth-library";
import * as Sentry from "@sentry/browser";
import { replace } from "lodash";
import { useCallback, useEffect } from "react";

const getSendingCountry = (transfer: any) => {
  return transfer.loggedInUserCountry.id;
};

const getCashInMethod = (transfer: any, paymentMethod: string) => {
  try {
    if (!transfer) return;
    if (!paymentMethod) return;
    if (
      transfer?.loggedInUserCountry == undefined ||
      transfer?.loggedInUserCountry == null
    ) {
      return [];
    }
    const { loggedInUserCountry } = transfer;
    const { cash_in_methods } = loggedInUserCountry;
    return cash_in_methods.find(
      (value: any) =>
        value.cash_in_method.payment_provider.name.toLowerCase() ===
        paymentMethod.toLowerCase()
    );
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getCashInMethodName = (transfer: any, paymentMethod: string) => {
  try {
    if (!transfer) return;
    if (!paymentMethod) return;
    const { loggedInUserCountry } = transfer;
    const { cash_in_methods } = loggedInUserCountry;
    return cash_in_methods.find(
      (value: any) =>
        value.cash_in_method.name.toLowerCase() === paymentMethod.toLowerCase()
    );
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const findCashInMethodByProviderType = (
  transfer: any,
  paymentMethod: string
) => {
  if (transfer?.loggedInUserCountry?.cash_in_methods == undefined) {
    return;
  }
  try {
    return transfer.loggedInUserCountry.cash_in_methods.find(
      (value: any) =>
        value.cash_in_method.payment_type.name.toLowerCase() ===
        paymentMethod.toLowerCase()
    );
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getReceivingCountry = (transfer: any, country_code: string) => {
  try {
    if (!transfer) return;
    if (!country_code) return;
    const { transferCountry } = transfer;
    const { receiving_countries } = transferCountry;
    return receiving_countries.find(
      (value: any) => value.receiving_country?.country_code === country_code
    );
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getPreferredComChannel = (countries: any, code: any) => {
  try {
    if (!countries) return;
    if (!code) return;
    const country = countries.find((item: any) => item?.country_code === code);
    if (!country) return null;
    return country.preferred_notification_channel;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getSelectedCashIbMethodId = (transfer: any, selectedMethod: string) => {
  try {
    if (!transfer) return;
    if (!selectedMethod) return;
    const found = transfer.loggedInUserCountry?.cash_in_methods?.find(
      (value: any) =>
        value.cash_in_method.payment_type.name.toLowerCase() ===
        selectedMethod.toLowerCase()
    );
    return found.cash_in_method.id;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getReceivingCountryID = (transfer: any, country_code: string) => {
  try {
    if (!transfer) return;
    if (!country_code) return;
    const receiving = transfer.loggedInUserCountry?.receiving_countries?.find(
      (value: any) => value.receiving_country?.country_code === country_code
    );
    return receiving?.receiving_country?.id;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const buildSelectInputData = (transfer: any) => {
  try {
    if (!transfer) return;
    return transfer.loggedInUserCountry?.cash_in_methods?.map((value: any) => {
      return {
        name: value.cash_in_method.name,
        type: value.cash_in_method.payment_type.name,
        provider: value.cash_in_method.payment_provider.name,
      };
    });
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
};

const selectPerviousUsersList = (favorite: any, transfer: any) => {
  try {
    return favorite.filter((val: any) => {
      if (
        val?.favorite.country.toLowerCase() ===
        transfer.bulkTransferData.country.toLowerCase()
      ) {
        return val.favorite.first_name;
      }
    });
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
};

const checkCashInLimit = (transfer: any, type: string, value: string) => {
  try {
    if (!transfer) return;
    if (!type) return;
    if (!value) return;
    if (type === "max") {
      return (
        parseFloat(value) > transfer?.loggedInUserCountry?.sending_max_amount
      );
    } else {
      return (
        parseFloat(value) < transfer?.loggedInUserCountry?.sending_min_amount
      );
    }
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const buildLimitError = (transfer: any, type: string, t: any) => {
  try {
    if (!transfer) return;
    if (!type) return;
    if (!t) return;
    if (type === "max") {
      return `${t("You_can_send_between")} ${
        transfer.loggedInUserCountry?.sending_min_amount
      } & ${transfer.loggedInUserCountry?.sending_max_amount}`;
    } else {
      return `${t("You_can_send_between")} ${
        transfer.loggedInUserCountry?.sending_min_amount
      } & ${transfer.loggedInUserCountry?.sending_max_amount}`;
    }
  } catch (error: any) {
    Sentry.captureException(error);
    return "";
  }
};

const applyRate = (value: any, rate: any, fromCurrency: any) => {
  try {
    if (!value) return;
    if (!rate) return;
    if (!fromCurrency) return;
    return fromCurrency === "EUR"
      ? parseFloat(value.target.value) * rate + ""
      : parseFloat(value.target.value) / rate + "";
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getCountryByCode = (countries: any, code: any) => {
  const country = countries.find((item: any) => item?.country_code === code);
  return country;
};

const translateError = (text: string, t: any) => {
  if (!text) return;
  return t(buildString(text?.toLowerCase()));
};

const getLoggedInUserReceivingCountries = (
  transfer: any,
  user: any,
  names = false
) => {
  try {
    if (!user) return;
    if (!transfer) return;
    const countries = transfer.danaPayCountries.find(
      (res: any) => res.country_code === user?.country_code
    );
    const all_receiving_countries =
      countries?.receiving_countries?.map((cc: any) => {
        if (names) {
          return cc.receiving_country.name;
        } else {
          return cc.receiving_country.code.toLowerCase();
        }
      }) ?? [];
    return all_receiving_countries;
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
};

const getCountryNameFromCode = (transfer: any, code: string) => {
  try {
    const country = transfer.danaPayCountries.find(
      (countryObject: any) => countryObject?.country_code === code
    );
    if (!country) return;
    return country.name;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getCountry = (transfer: any, name: string) => {
  try {
    const country = transfer.danaPayCountries.find(
      (countryObject: any) => countryObject.name === name
    );
    if (!country) return;
    return country;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const calculateTotalFromArray = (usersObject: any) => {
  try {
    if (!usersObject) return;
    let total = 0;
    const _selected: any = Object.values(usersObject);
    total = _selected.reduce(
      (sum: number, current: any) => sum + parseFloat(current.enteredValue),
      0
    );
    return { total, users: _selected, total_cfa: total * 655.957 };
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const calculateSumFromArray = (usersObject: any) => {
  try {
    if (!usersObject) return;
    let total: any = 0;
    total = usersObject.reduce(
      (sum: number, current: any) =>
        sum + parseFloat(current.amount_without_fees),
      0
    );
    return { total, users: usersObject, total_cfa: total * 655.957 };
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const buildString = (text: string) => {
  try {
    if (!text) return "";
    const err = text
      ?.trim()
      ?.toLowerCase()
      .replace(/[-'.]/g, "")
      .replaceAll(" ", "_");
    return err;
  } catch (error: any) {
    Sentry.captureException(error);
    return "";
  }
};

const performPDFDownload = (response: any, id: string) => {
  const blob = new Blob([response], { type: response.type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.setAttribute("download", "invoice-" + id);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const performCSVDownload = (response: any, id: string, type: string) => {
  const blob = new Blob([response], { type: `text/csv` });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.setAttribute("download", `${type}_${id}`);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const performCSVDownloadnew = (response: any, filename: string) => {
  const blob = new Blob([response], { type: `text/csv` });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.setAttribute("download", `${filename}`);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

// remove the
const cleanObject = (obj: any) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "All"
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

// google sheet csv parser
function parseGoogleSheetCSV(csvText: String) {
  try {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0].split(","); // Extract headers (assumes the first row is the header row)
    const data = []; // Initialize an array to store parsed data
    let emailArray: String[] = [];
    for (let i = 1; i < rows.length; i++) {
      const rowData = rows[i].split(","); // Split the row, handling '\r' characters
      emailArray = emailArray.concat(rowData);
      const rowObject: any = {};
      for (let j = 0; j < headers.length; j++) {
        rowObject[headers[j]] = rowData[j];
      }
      data.push(rowObject);
    }
    // return data;
    return emailArray;
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
}

// google sheet helper
const getGoogleDoc = () => {
  try {
    const serviceAccountAuth = new JWT({
      // env var values here are copied from service account credentials generated by google
      // see "Authentication" section in docs for more info
      email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
      key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY,
      scopes: ["https://www.googleapis.com/auth/spreadsheets"],
    });

    const doc = new GoogleSpreadsheet(
      process.env.REACT_APP_GOOGLE_SHEET_ID!,
      serviceAccountAuth
    );

    return doc;
  } catch (error: any) {
    Sentry.captureException(error);
    return undefined;
  }
};

const getGoogleSheetRowData = async (doc: any) => {
  try {
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsByIndex[0]; // or use `doc.sheetsById[id]` or `doc.sheetsByTitle[title]`
    const rows = await sheet.getRows();
    return rows.map((rowItem: any) => rowItem._rawData[0]);
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
};

const isUserNotRegisteredOnce = (user: any) => {
  try {
    if (user.is_individual) {
      const fields = [
        "country",
        "country_code",
        "first_name",
        "last_name",
        "monthly_revenue",
        "job",
        "phone_number",
      ];
      return fields.every(
        (field: any) => user[field] == undefined || user[field] == null
      );
    } else {
      const fields = [
        "country",
        "country_code",
        "first_name",
        "last_name",
        "phone_number",
        "company",
      ];
      return fields.every(
        (field: any) => user[field] == undefined || user[field] == null
      );
    }
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

// clear multiple localstorage values
const clearLocalStorageValues = (props: string[]) => {
  props.forEach((localStorageKey: string) => {
    window.localStorage.removeItem(localStorageKey);
  });
};

const getCurrentDate = () => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${day}-${month}-${year}`;
  return currentDate;
};

// valid data return
const isValidData = (data: any) => {
  if (data !== undefined && data !== null && data !== "") {
    return true;
  } else {
    return false;
  }
};

const checkLoggedIn = (
  user: any,
  signUpData: any,
  isEmpty: any,
  history: any
) => {
  try {
    if (localStorage.getItem("user:key")) {
      if (Object.keys(signUpData).length < 2) {
        history.push("/register?page=0");
        return;
      }

      const usertype = window.localStorage.getItem("acctype");
      if (usertype && usertype == "Individual") {
        if (
          isEmpty(signUpData?.first_name) ||
          isEmpty(signUpData?.last_name) ||
          isEmpty(signUpData?.address_line) ||
          isEmpty(signUpData?.city)
        ) {
          history.push("/register?page=1");
        } else if (
          (isEmpty(user?.monthly_revenue) &&
            isEmpty(signUpData?.monthly_revenue)) ||
          (isEmpty(user?.activity_id) &&
            isEmpty(user?.job) &&
            isEmpty(signUpData?.activity_id) &&
            isEmpty(signUpData?.job))
        ) {
          history.push("/register?page=2");
        } else if (isEmpty(signUpData?.receiving_countries_ids)) {
          history.push("/register?page=individualTransferInfo");
        } else if (isEmpty(signUpData?.residency)) {
          history.push("/register?page=residenceSelection");
        } else {
          history.push("/register?page=4");
        }
      } else if (usertype == "Business") {
        if (
          isEmpty(signUpData?.first_name) ||
          isEmpty(signUpData?.last_name) ||
          isEmpty(signUpData?.phone_number) ||
          isEmpty(signUpData?.country_code)
        ) {
          history.push("/register?page=1");
        } else if (signUpData?.company == undefined) {
          history.push("/register?page=2");
        } else if (
          isEmpty(signUpData?.monthly_revenue) ||
          (isEmpty(signUpData?.activity_id) && isEmpty(signUpData?.job))
        ) {
          history.push("/register?page=2");
        } else {
          history.push("/register?page=companyEnvisionedCountries");
        }
      }
    } else {
      history.push("/login");
    }
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

const isEmpty = (val: any) => {
  return val === undefined || val == null || val == "" || val.length <= 0
    ? true
    : false;
};
const getCashoutMethodIdForFees = (widthdrawMethod: any, allMethods: any) => {
  try {
    if (widthdrawMethod == "Mobile Money") {
      const id = allMethods.find(
        (method: any) =>
          method?.cashout_method?.payment_type?.name == "mobile_money"
      );
      if (id) {
        return id?.cash_out_method?.id;
      }
    } else if (widthdrawMethod == "Bank Account") {
      const id = allMethods.find(
        (method: any) =>
          method?.cashout_method?.payment_type?.name == "bank_transfer" ||
          method?.cashout_method?.payment_type?.name == "manual_bank_transfer"
      );
      if (id) {
        return id?.cash_out_method?.id;
      }
    } else if (widthdrawMethod == "Microfinance") {
      const id = allMethods.find(
        (method: any) => method?.cashout_method?.payment_type?.name == "mfi"
      );
      if (id) {
        return id?.cash_out_method?.id;
      }
    } else if (widthdrawMethod == "delivery") {
      const id = allMethods.find(
        (method: any) =>
          method?.cashout_method?.payment_type?.name == "delivery"
      );
      if (id) {
        return id?.cash_out_method?.id;
      }
    }
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

const getGoogleSheetData = (googleSheetData: any) => {
  try {
    if (
      googleSheetData !== undefined &&
      googleSheetData !== null &&
      googleSheetData.length > 0
    ) {
      let emails: any = [];
      googleSheetData.forEach((sheets: any) => {
        if (sheets?.data !== undefined && sheets?.data?.length > 0) {
          sheets?.data.forEach((emailObject: any) => {
            if (Object.values(emailObject).length > 0) {
              emails = emails.concat(Object.values(emailObject));
            }
          });
        }
      });
      return emails;
    }
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

const redirectToRegister = (
  user: any,
  signUpData: any,
  isEmpty: any,
  history: any
) => {
  try {
    const usertype = window.localStorage.getItem("acctype");
    const isUpdating = window.localStorage.getItem("isUpdate");

    // dont redirect if user is active or if user has company object craeted when completing the company flow
    if (
      user?.is_active ||
      user?.on_boarding_status == "submitted" ||
      user?.is_verified ||
      user?.client?.type == "customer" ||
      !isEmpty(user?.kyc_submitted_at) ||
      !isEmpty(user?.company?.kyb_submitted_at) ||
      (user?.company !== undefined &&
        user?.company !== null &&
        user?.is_individual == false) ||
      isUpdating
    ) {
      return;
    } else {
      if (localStorage.getItem("user:key")) {
        history.push("/register", { replace: true });
      } else {
        history.push("/login", { replace: true });
      }
    }

    // if (localStorage.getItem("user:key")) {
    //   if (usertype && usertype == "Individual") {
    //     if (
    //       isEmpty(user?.first_name) ||
    //       isEmpty(user?.last_name) ||
    //       isEmpty(user?.address_line) ||
    //       isEmpty(user?.city) ||
    //       isEmpty(user?.monthly_revenue) ||
    //       (isEmpty(user?.activity_id) && isEmpty(user?.job)) ||
    //       isEmpty(user?.residency) ||
    //       (isEmpty(user?.receiving_countries_ids) &&
    //         isEmpty(signUpData?.receiving_countries_ids))
    //     ) {
    //       history.push("/register", { replace: true });
    //     }
    //   } else if (usertype == "Business") {
    //     if (
    //       isEmpty(user?.first_name) ||
    //       isEmpty(user?.last_name) ||
    //       isEmpty(user?.phone_number) ||
    //       isEmpty(user?.country_code) ||
    //       user?.company == undefined ||
    //       isEmpty(user?.monthly_revenue) ||
    //       (isEmpty(user?.activity_id) && isEmpty(user?.job))
    //     ) {
    //       history.push("/register", { replace: true });
    //     }
    //   }
    // } else {
    //   history.push("/login");
    // }
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

const handleRedirection = (user: any, history: any) => {
  if (
    user.is_active ||
    user.is_verified ||
    user?.client?.type == "customer" ||
    !isEmpty(user?.kyc_submitted_at) ||
    !isEmpty(user?.company?.kyb_submitted_at)
  ) {
    history.push("/dashboard/home");
  } else {
    if (Array.isArray(user?.meta)) {
      history.push("/register");
      return;
    }
    if (
      user?.progression?.usertype == "individual" ||
      user?.progression?.usertype == "Individual"
    ) {
      if (
        (isEmpty(user?.first_name) && isEmpty(user?.progression?.first_name)) ||
        (isEmpty(user?.last_name) && isEmpty(user?.progression?.last_name)) ||
        (isEmpty(user?.address_line) &&
          isEmpty(user?.progression?.address_line)) ||
        (isEmpty(user?.city) && isEmpty(user?.progression?.city))
      ) {
        history.push("/register?page=1&usertype=individual");
        return;
      } else if (
        (isEmpty(user?.monthly_revenue) &&
          isEmpty(user?.progression?.monthly_revenue)) ||
        (isEmpty(user?.activity_id) &&
          isEmpty(user?.job) &&
          isEmpty(user?.progression?.activity_id) &&
          isEmpty(user?.progression?.job))
      ) {
        history.push("/register?page=2");
      } else if (
        isEmpty(user?.receiving_countries_ids) &&
        isEmpty(user?.progression?.receiving_countries_ids)
      ) {
        history.push("/register?page=individualTransferInfo");
      } else if (
        isEmpty(user?.residency) &&
        isEmpty(user?.progression?.residency)
      ) {
        history.push("/register?page=residenceSelection");
      } else if (user?.client?.type !== "customer") {
        if (user?.progression?.country_code) {
          if (isFromAfrica(user?.progression?.country_code)) {
            history.push("/register?page=individualTransferInfo");
          } else {
            history.push("/register?page=residenceSelection");
          }
        } else {
          history.push("/register?page=individualTransferInfo");
        }
      } else {
        history.push("/register?page=4");
      }
    } else if (
      user?.progression?.usertype == "Business" ||
      user?.progression?.usertype == "business"
    ) {
      if (
        (isEmpty(user?.first_name) && isEmpty(user?.progression?.first_name)) ||
        (isEmpty(user?.last_name) && isEmpty(user?.progression?.last_name)) ||
        (isEmpty(user?.phone_number) &&
          isEmpty(user?.progression?.phone_number)) ||
        (isEmpty(user?.country_code) &&
          isEmpty(user?.progression?.country_code))
      ) {
        history.push("/register?page=1&usertype=business");
        return;
      } else if (
        (isEmpty(user?.monthly_revenue) &&
          isEmpty(user?.progression?.monthly_revenue)) ||
        (isEmpty(user?.activity_id) &&
          isEmpty(user?.job) &&
          isEmpty(user?.progression?.job) &&
          isEmpty(user?.progression?.activity_id))
      ) {
        history.push("/register?page=2&usertype=business");
        return;
      } else if (
        isEmpty(user?.receiving_countries_ids) &&
        isEmpty(user?.progression?.receiving_countries_ids)
      ) {
        history.push(
          "/register?page=companyEnvisionedCountries&usertype=business"
        );
        return;
      } else {
        history.push(
          "/register?page=companyEnvisionedCountries&usertype=business"
        );
        return;
      }
    } else {
      history.push("/register");
    }
  }
};
function disableSpaceInput(e: any, type: string) {
  if (type == "email") {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key == 32) {
      //space
      e.preventDefault();
    }
  }
}

function disableSpacePaste(type: any, string: string) {
  if (type == "email") {
    return string.split(" ").join("");
  } else {
    return string;
  }
}
const isFromAfrica = (country_code: any) => {
  try {
    return [2, "2"].includes(country_code.toString().charAt(0));
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

const hasUserCompletedRegistration = (user: any) => {
  try {
    return (
      user?.client?.type == "customer" || user?.is_active || user?.is_verified
    );
  } catch (error) {
    Sentry.captureException(error);
  }
};
function useDebounce(effect: any, dependencies: any, delay: any) {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

function prependZero(digit: any) {
  if (digit < 10) {
    return "0" + digit;
  } else {
    return digit;
  }
}

export {
  getGoogleSheetData,
  getCashoutMethodIdForFees,
  getSendingCountry,
  getReceivingCountry,
  getCashInMethod,
  getPreferredComChannel,
  getCountryByCode,
  getSelectedCashIbMethodId,
  getReceivingCountryID,
  buildSelectInputData,
  checkCashInLimit,
  buildLimitError,
  applyRate,
  translateError,
  getLoggedInUserReceivingCountries,
  getCountryNameFromCode,
  calculateTotalFromArray,
  buildString,
  getCountry,
  performPDFDownload,
  cleanObject,
  selectPerviousUsersList,
  calculateSumFromArray,
  performCSVDownload,
  getCashInMethodName,
  performCSVDownloadnew,
  parseGoogleSheetCSV,
  getGoogleDoc,
  isUserNotRegisteredOnce,
  getGoogleSheetRowData,
  clearLocalStorageValues,
  findCashInMethodByProviderType,
  getCurrentDate,
  isValidData,
  checkLoggedIn,
  isEmpty,
  redirectToRegister,
  handleRedirection,
  disableSpaceInput,
  disableSpacePaste,
  isFromAfrica,
  hasUserCompletedRegistration,
  useDebounce,
  useAutosizeTextArea,
  prependZero,
};
