import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

const PhoneSearchModal = (props: any) => {
  const { all_countries, setSelected, setCountry, setcountryCodeDropdownOpen } =
    props;

  const [searchText, setsearchText] = useState("");
  const [filteredSearchListing, setfilteredSearchListing] = useState([]);
  const [listing, setlisting] = useState([]);

  useEffect(() => {
    try {
      if (searchText !== "") {
        const filteredResults = all_countries.filter(
          (country: any) =>
            country.name.toLowerCase().includes(searchText.toLowerCase()) ||
            country.country_code
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );
        setlisting(filteredResults);
      } else {
        setlisting(all_countries);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [searchText, all_countries]);

  return (
    <div className="z-10 absolute left-0 w-full top-[58px] border-1 pl-4 pr-4 border-[rgba(0,0,0,0.2)] !rounded-xl h-[321px] bg-[#FAFAFA]">
      <div className="flex items-center">
        <img
          className="p-1"
          src={`./images/magnifyingglass.svg`}
          style={{ height: 24, width: 40 }}
        />
        <input
          type="text"
          className="min-h-[42px] font-bold opacity-50"
          value={searchText}
          onChange={(e: any) => setsearchText(e.target.value)}
          //   placeholder={placeholder}
        />
      </div>
      <hr />
      <div className="w-full overflow-y-scroll h-[270px] pl-2 pr-2">
        {listing?.map((item: any, index: number) => (
          <div className="" key={index}>
            <div
              className="flex flex-row items-center my-3.5 cursor-pointer"
              onClick={() => {
                setCountry(item);
                setSelected(item);
                setcountryCodeDropdownOpen(false);
              }}
              key={index}
            >
              <img
                src={`../flags/${item.country_code}.png`}
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 15,
                }}
                className="mr-2"
                alt=""
              />
              <div>{item?.name}</div>

              <div className="ml-auto">+{item?.country_code}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhoneSearchModal;
