import { isEmpty, useAutosizeTextArea } from "../../utilities/help";
import * as Sentry from "@sentry/browser";
import { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";

const GoogleAutoCompleteField = (props: any) => {
  const {
    value = "",
    className,
    label,
    placeholder,
    onChange = () => {},
    getPostalCode = () => {},
    getLocality = () => {},
    country = null,
    containerClasses = "w-full mb-1 relative",
  } = props;
  const { t } = useTranslation();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      types: ["address"],
      componentRestrictions: { country: !isEmpty(country) ? country : "" },
    },
  });

  const [places, setplaces]: any = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);

  useEffect(() => {
    try {
      if (placePredictions.length) {
        setisModalOpen(true);
        setplaces(placePredictions);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [placePredictions, placesService]);

  // set postal code, city if present
  const handleSetPlace = (place_id: any) => {
    try {
      placesService?.getDetails(
        {
          placeId: place_id,
        },
        (placeDetails: any) => {
          const hasPostalCode =
            placeDetails &&
            placeDetails.address_components &&
            placeDetails.address_components.find((addressComponent: any) => {
              return addressComponent.types.includes("postal_code");
            });
          const hasLocality =
            placeDetails &&
            placeDetails.address_components &&
            placeDetails.address_components.find((addressComponent: any) => {
              return addressComponent.types.includes("locality");
            });
          if (hasPostalCode) {
            getPostalCode &&
              getPostalCode(
                hasPostalCode.long_name || hasPostalCode.short_name
              );
          } else {
            getPostalCode && getPostalCode("");
          }
          if (hasLocality) {
            getLocality &&
              getLocality(hasLocality.long_name || hasLocality.short_name);
          } else {
            getLocality && getLocality("");
          }
          onChange(placeDetails);
        }
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // resposive text area
  const textAreaRef: any = useRef();
  useAutosizeTextArea(textAreaRef.current, value);

  return (
    <div className={containerClasses}>
      {label && (
        <label className="mb-1 text-gray-600 text-[12.6px]">{label}</label>
      )}
      <textarea
        placeholder={placeholder}
        style={{ resize: "none" }}
        ref={textAreaRef}
        value={value}
        rows={3}
        className={className}
        name="address"
        id="adress2"
        onChange={(e: any) => {
          getPlacePredictions({
            input: e.target.value,
            componentRestrictions: {
              country: !isEmpty(country) ? [country] : [],
            },
          });
          onChange(e.target.value);
        }}
      ></textarea>
      {places.length > 0 && isModalOpen && !isPlacePredictionsLoading && (
        <div className="p-2 relative shadow-lg rounded-lg">
          <div
            className="block mb-1 rounded-lg text-sm text-white p-2 w-min !ml-auto text-white bg-[#666] cursor-pointer"
            onClick={() => setisModalOpen(false)}
          >
            {t("close")}
          </div>
          <div className="overflow-hidden">
            {places.map((place: any, placeIndex: any) => (
              <div
                key={place?.place_id}
                className={`${
                  placeIndex !== places.length - 1 && "border-b-2"
                } hover:bg-[#666] hover:text-white p-2 cursor-pointer`}
                onClick={() => {
                  handleSetPlace(place?.place_id);
                  setisModalOpen(false);
                }}
              >
                {place?.description}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleAutoCompleteField;
